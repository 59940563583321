import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import colors from "../../styles/colors"
import { Header4 } from "../typography"

const Wrapper: any = styled.div`
  display: block;

  text-decoration: none;
  position: relative;

  cursor: pointer;

  width: 100%;
  height: ${(props: any) => (props.inline ? "430px" : "auto")};

  &:hover {
    .gatsby-image-wrapper img {
      transform: scale(1.05);
    }
  }

  &:after {
    display: ${(props: any) => (props.inline ? "block" : "none")};
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 160px;
    width: 100%;

    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.6) 62.67%
    );

    z-index: 1;
  }

  .gatsby-image-wrapper {
    width: 100%;
    height: ${(props: any) => (props.inline ? "100%" : "400px")};

    img {
      transition: transform 0.5s ease;
    }
  }

  > div:last-child {
    position: ${(props: any) => (props.inline ? "absolute" : "static")};
    bottom: 16px;
    left: 16px;
    width: ${(props: any) => (props.inline ? "calc(100% - 32px)" : "100%")};

    margin-top: ${(props: any) => (props.inline ? "0" : "24px")};
    text-align: ${(props: any) => (props.inline ? "center" : "left")};
    padding-top: 8px;

    z-index: 2;

    border-top: 1px solid ${colors.creamLight};

    color: ${colors.creamLight};

    span {
      font-family: "Founders Grotesk";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      /* identical to box height, or 117% */

      letter-spacing: 0.1em;
      text-transform: uppercase;

      margin: 8px 0;
      display: block;
    }
  }
`
export default function MerchCard({ merch, inline = false }) {
  return (

    // <Wrapper as={Link} to={`/merch/${merch.slug}`} inline={inline}>
    //   <GatsbyImage
    //     image={merch.productImage.gatsbyImage}
    //     alt={merch.productTitle}
    //   />
    //   <div>
    //     {/* <span>Merch</span> */}
    //     <Header4>{merch.productTitle}</Header4>
    //   </div>
    // </Wrapper>

    <Wrapper as={Link} to={`/merch/${merch.slug}`} inline={inline}>
      <GatsbyImage
        image={merch.merchImage.gatsbyImage}
        alt={merch.title}
      />
      <div>
        {/* <span>Merch</span> */}
        <Header4>{merch.title}</Header4>
      </div>
    </Wrapper>
  )
}
