import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import PageHeader from "../components/common/PageHeader"
import { BodyLarge } from "../components/typography"
import colors from "../styles/colors"
import MerchCard from "../components/common/MerchCard"
import RecipeGrid from "../components/recipes/RecipeGrid"
import styled from "styled-components"

import Seo from "../components/seo"

const Header = styled.div`
  color: ${colors.creamLight};

  margin: 0 auto;

  padding: 48px 24px;

  border-bottom: 1px solid ${colors.creamLight};

  text-align: center;
`

const Wrapper = styled.div`
  color: ${colors.creamLight};
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.6) 75%
  );
  background-position: bottom;
  background-size: cover;

  padding: 24px 48px;

  display: flex;
  justify-content: center;

  min-height: 70vh;
  align-items: center;

  > div {
    text-align: center;

    ${BodyLarge} {
      margin-bottom: 24px;
    }

    a {
      margin-top: 40px;
    }
  }
`
// query for contentfulMerch
const query = graphql`
  query MerchQuery {
    allContentfulMerch {
      nodes {
        id
        title
        shopifyId
        merchImage {
          gatsbyImage(width: 560, placeholder: BLURRED)
        }
        slug
      }
    }
  }
`

// const query = graphql`
//   query MyQuery {
//     allContentfulProduct {
//       nodes {
//         id
//         productTitle
//         productType
//         shopifyId
//         productImage {
//           gatsbyImage(width: 560, placeholder: BLURRED)
//         }
//         slug
//       }
//     }
//   }
// `

export function Head() {
  return <Seo title={"Merch"} />
}

export default function GenericTemplate() {
  const data = useStaticQuery(query)
  const merch = data.allContentfulMerch.nodes

  // const merch = data.allContentfulProduct.nodes.filter(
  //   item => item.productType === "merch"
  // );

  return (
    <Layout>
      <PageHeader
        header="Merch"
        subheader="We love merch. Our merch is top of the line"
        body={null}
        cta={null}
        onCtaClick={null}
      />
      <Wrapper>
        <RecipeGrid>
          {merch.map(item => (
            <MerchCard merch={item} inline />
          ))}
        </RecipeGrid>
      </Wrapper>
    </Layout>
  )
}
